import React, { useState } from "react"
import Layout from "../layout/layout"
import ReactHtmlParser from "react-html-parser"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import HomestayVideo from "../video/Homestay.mp4"
import Video from "../components/Video/LargeVideo"
import ButtonCardItem from "../components/ButtonCards/NewTabButton"
import { CardGrid, CardWrapper } from "../components/ButtonCards/CardElements"
import Logo from "../images/logo.png"
import { supportBanner } from "../images"
import Accordion from "../components/Accordions/WorkWithUsHomestayFAQ"
import LargeVideo from "../components/Video/LargeVideo"
import { SupportTestimony } from "../components/Testimonials"
import { CentreWrapper, RealButton } from "../components/ButtonElement"
import { SupportPopup } from "../components/FormPopup"
import SEO from "../components/seo"
import { navigate } from "gatsby"

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const ApplicationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const sidebar = [
  {
    to: "Immigration",
    title: "移民服務",
  },
  {
    to: "Relocation",
    title: "安置服務",
  },
  {
    to: "Insurance",
    title: "保險",
  },
]

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
      <br />
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;

  @media (max-width: 1200px) {
    margin: 40px 100px;
  }

  @media (max-width: 900px) {
    margin: 40px 50px;
  }

  @media (max-width: 740px) {
    margin: 9px;
  }
`

export default function SupportServices({ data }) {
  const html = data.allWpPage.edges
  const Family = ReactHtmlParser(html[0].node.content)
  const Immigration = ReactHtmlParser(html[1].node.content)
  const StudyAbroad = ReactHtmlParser(html[2].node.content)
  const [formType, setFormType] = useState("")
  return (
    <div>
      <Layout
        sidebar={sidebar}
        pageTitle="支持服務"
        background={supportBanner}
        pageDescription="爲留學生及其家人提供的額外服務"
      >
        <SEO
          title="Support Services | Immigration | Relocation | Insurance"
          description="Study Links offers a range of complementary services for international students and their families, including immigration, relocation and insurance support."
        />
        <Section id="Immigration">
          <FadeH1>移民服務</FadeH1>
          {Immigration.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton onClick={() => navigate("/contact")}>
              聯繫我們
            </RealButton>
          </CentreWrapper>
          <SupportPopup formType={formType} />
        </Section>
        <Section id="Relocation">
          <FadeH1>安置服務</FadeH1>
          {Family.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton onClick={() => navigate("/contact")}>
              聯繫我們
            </RealButton>
          </CentreWrapper>
        </Section>
        <Section id="Insurance">
          <FadeH1>保險</FadeH1>
          {StudyAbroad.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <RealButton onClick={() => navigate("/contact")}>
              聯繫我們
            </RealButton>
          </CentreWrapper>
        </Section>
        <SupportTestimony />
      </Layout>
    </div>
  )
}

export const STUDYLINKS_SUPPORTSERVICES = graphql`
  query studylinks_supportservices {
    allWpPage(
      filter: {
        title: {
          in: [
            "api-studylinks-simplified-support services-Family Relocation Services"
            "api-studylinks-simplified-support services-Immigration Services"
            "api-studylinks-simplified-support services-Study Abroad Insurance"
          ]
        }
      }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`
