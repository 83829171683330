import React, { useState } from "react"
import styled from "styled-components"
import { RealButton } from "../ButtonElement"


const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const FormSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 10px 30px 10px;
`

function Form({formType}) {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    number: "",
    service: formType,
    message: "",
    success: false,
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "EducationContact", ...formState }),
    })
      .then(() => {
        setFormState({
          name: "",
          email: "",
          number: "",
          service: formType,
          message: "",
          success: true,
        })

      })
      .catch(error => alert(error))
    e.preventDefault()
  }

  return (
      <div>

        {formState.success && <div class="alert alert-success" role="alert">
          Your message has been submitted
        </div>}
          <form
            onSubmit={handleSubmit}
            name="EducationContact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <FormContainer>
              <FormSubContainer>
                <div class="form-group">
                  <label for="exampleFormControlInput1">Name</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="exampleFormControlInput1"
                    placeholder="John Smith"
                    name="name"
                    onChange={handleChange}
                    value={formState.name}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlInput2">Email address</label>
                  <input
                    required
                    type="email"
                    class="form-control"
                    id="exampleFormControlInput2"
                    placeholder="name@example.com"
                    name="email"
                    onChange={handleChange}
                    value={formState.email}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlInput3">Phone number</label>
                  <input
                    required
                    type="tel"
                    class="form-control"
                    id="exampleFormControlInput3"
                    placeholder="e.g. +44 (0)1274 679710"
                    name="number"
                    onChange={handleChange}
                    value={formState.number}
                  />
                </div>
                <div class="form-group">
                  <label for="exampleFormControlSelect1">
                    Please select service
                  </label>
                  <select
                    class="form-control"
                    id="exampleFormControlSelect1"
                    value={formState.service}
                    onChange={handleChange}
                    name="service"
                  >
                    <option value="Careers Advice">Careers Advice</option>
                    <option value="Course Applications">Course Applications</option>
                    <option value="Private Tuition - Online">Private Tuition - Online</option>
                    <option value="Private Tuition - In person">Private Tuition - In person</option>
                    <option value="Cultural & Etiquette training">Cultural & Etiquette training</option>
                    <option value="Holiday Camps">Holiday Camps</option>
                    <option value="Familiarisation Camps">Familiarisation Camps</option>
                    <option value="Study tours">Study tours</option>
                    <option value="Exchange programmes">Exchange programmes</option>
                    <option value="School twinning programmes">School twinning programmes</option>
                  </select>
                </div>
              </FormSubContainer>
              <FormSubContainer>
                <div class="form-group">
                  <label for="exampleFormControlTextarea1">Your Message:</label>
                  <textarea
                    required
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="10"
                    name="message"
                    onChange={handleChange}
                    value={formState.message}
                  ></textarea>
                </div>
                <RealButton type="submit">Submit</RealButton>
              </FormSubContainer>
            </FormContainer>
          </form>
      </div>
  )
}

function SupportForm({formType}) {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    number: "",
    service: formType,
    message: "",
    success: false,
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "SupportContact", ...formState }),
    })
      .then(() => {
        setFormState({
          name: "",
          email: "",
          number: "",
          service: formType,
          message: "",
          success: true,
        })

      })
      .catch(error => alert(error))
    e.preventDefault()
  }

  return (
      <div>

        {formState.success && <div class="alert alert-success" role="alert">
          Your message has been submitted
        </div>}
          <form
            onSubmit={handleSubmit}
            name="SupportContact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <FormContainer>
              <FormSubContainer>
                <div class="form-group">
                  <label for="supportFormControlInput1">Name</label>
                  <input
                    required
                    type="text"
                    class="form-control"
                    id="supportFormControlInput1"
                    placeholder="John Smith"
                    name="name"
                    onChange={handleChange}
                    value={formState.name}
                  />
                </div>
                <div class="form-group">
                  <label for="supportFormControlInput2">Email address</label>
                  <input
                    required
                    type="email"
                    class="form-control"
                    id="supportFormControlInput2"
                    placeholder="name@example.com"
                    name="email"
                    onChange={handleChange}
                    value={formState.email}
                  />
                </div>
                <div class="form-group">
                  <label for="supportFormControlInput3">Phone number</label>
                  <input
                    required
                    type="tel"
                    class="form-control"
                    id="supportFormControlInput3"
                    placeholder="e.g. +44 (0)1274 679710"
                    name="number"
                    onChange={handleChange}
                    value={formState.number}
                  />
                </div>
                <div class="form-group">
                  <label for="supportFormControlSelect1">
                    Please select service
                  </label>
                  <select
                    class="form-control"
                    id="supportFormControlSelect1"
                    value={formState.service}
                    onChange={handleChange}
                    name="service"
                  >
                    <option value="Immigration Services">Immigration Services</option>
                    <option value="Relocation Services">Relocation Services</option>
                    <option value="Insurance">Insurance</option>
                  </select>
                </div>
              </FormSubContainer>
              <FormSubContainer>
                <div class="form-group">
                  <label for="supportFormControlTextarea1">Your Message:</label>
                  <textarea
                    required
                    class="form-control"
                    id="supportFormControlTextarea1"
                    rows="10"
                    name="message"
                    onChange={handleChange}
                    value={formState.message}
                  ></textarea>
                </div>
                <RealButton type="submit">Submit</RealButton>
              </FormSubContainer>
            </FormContainer>
          </form>
      </div>
  )
}

export const EducationPopup = ({formType}) => {
  return (
    <div
      class="modal fade"
      id="exampleModal1"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Education services
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Form formType={formType}/>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SupportPopup = ({formType}) => {
  return (
    <div
      class="modal fade"
      id="supportModal1"
      tabindex="-1"
      role="dialog"
      aria-labelledby="supportModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="supportModalLabel">
              Education services
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <SupportForm formType={formType}/>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}